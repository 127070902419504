.logo {
  color: #75e6da;
  height: 60px;
  display: flex;
  align-items: center;

  span {
    padding-left: 10px;
    font-size: 18px;
    margin-top: 3px;
    color: #fff;
  }

  img {
    height: 70%;
    position: relative;
    top: 3px;
  }
}

.dark {
  span {
    color: #06445e;
  }
}

@primary-color: #179AB4;@link-color: #179AB4;@layout-header-background: #06445E;@border-radius-base: 2px;