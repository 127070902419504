.container {
  padding: 10px;
}

.card {
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: #d5f1f5;
  }
}

.label {
  margin-top: 10px;
}

.selected {
  background-color: #06445e !important;
  color: #fff !important;
}

@primary-color: #179AB4;@link-color: #179AB4;@layout-header-background: #06445E;@border-radius-base: 2px;