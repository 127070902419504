.container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.content {
  max-width: 1200px;
  padding: 20px;
  width: 100%;
}

@primary-color: #179AB4;@link-color: #179AB4;@layout-header-background: #06445E;@border-radius-base: 2px;