.header {
  padding-left: 25px;
  padding-right: 25px;
  justify-content: space-between;
  display: flex;
}

.mobile {
  display: flex;
  justify-content: space-between;
}

.menuButton {
  color: #fff;
  width: 75px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: -25px;
  padding-left: 25px;
}

.menu {
  display: flex;
  align-items: center;
  button {
    color: #fff;
  }
}

@primary-color: #179AB4;@link-color: #179AB4;@layout-header-background: #06445E;@border-radius-base: 2px;