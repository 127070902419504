.ant-btn {
  svg {
    margin-right: 8px;
  }
  *:last-child {
    margin-right: 0;
  }
}

// Mobile
@media (max-width: 767px) {
  .ant-page-header-heading-extra {
    .ant-btn {
      & > svg {
        margin-right: 0;
      }
      & > span {
        display: none;
      }
    }
  }
}

.ant-ribbon-wrapper {
  width: 100%;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  color: #aaa;
  &:hover {
    color: #666;
  }
}

@primary-color: #179AB4;@link-color: #179AB4;@layout-header-background: #06445E;@border-radius-base: 2px;